<template>
  <component :is="formData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No Formulaire found -->
    <b-alert variant="danger" :show="formData === undefined">
      <h4 class="alert-heading">Erreur lors de chargement du contenu</h4>
      <div class="alert-body">
        Aucun element trouvé avec cet identifiant. Vérifier
        <b-link class="alert-link" :to="{ name: 'form-list' }">
          Liste des elements
        </b-link>
        pour les autres element.
      </div>
    </b-alert>

    <data-add-tab :form-data="formData" class="mt-2" />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";

import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import storeModule from "./form-list/storeModule";
import DataAddTab from "./form-list/FormData.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormCheckbox,
    DataAddTab,
    BButton,
    // DataAddTabInformation,
    // DataAddTabSocial,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
    };
  },
  mounted() {
    if (router.currentRoute.name == "form-add-new") {
      this.operationTitle = "Nouveau Formulaire";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "form-view") {
      this.operationTitle = "Détails du Formulaire";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "form-edit") {
      this.operationTitle = "Modification du Formulaire";
      this.operationIcon = "EditIcon";
    }
  },
  methods: {
    prevPage() {
      router.push({ name: "form-list" });
    },
    convertBoolToInt(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    let formData = ref(null);
    let APP_STORE_MODULE_NAME = "app-form";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    var now = new Date();

    var defaultformData = ref({
      name: "",
      sku_form: "F-" + now.getTime(),
      director: "",
      country: "",
      state: "",
      town: "",
    });

    if (router.currentRoute.name == "form-add-new") {
      // init empty form data
      formData = defaultformData;
    } else {
      // requirement //
      store
        .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          formData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            formData.value = undefined;
          }
        });
    }

    return {
      formData,
    };
  },
};
</script>
