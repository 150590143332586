<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="formData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="4">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Formulaire
            </h6>
          </b-col>

          <b-col cols="8">
            <b-button
              v-if="$router.name == 'form-list-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'form-list-view',
                  params: { id: formData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'form-list-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'form-list-view',
                  params: { id: formData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'form-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Formulaires</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col cols="12" xl="12" md="12">
            <b-card no-body class="invoice-preview-card">
              <b-row class="">
                <b-col cols="6" md="3">
                  <validation-provider
                    #default="validationContext"
                    name="sku_department"
                    rules="required"
                  >
                    <!-- sku_department -->
                    <b-form-group
                      label="Département"
                      label-for="sku_department"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="formData.sku_department"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="departmentOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="sku_department"
                        @change="getDepartmentModules(formData.sku_department)"
                        @select="getDepartmentModules('select')"
                        @search="getDepartmentModules('search')"
                        @input="getDepartmentModules(formData.sku_department)"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Completer département s'il vous plait
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <b-col cols="6" md="3" v-if="formData.sku_department">
                  <!-- departement -->
                  <b-form-group label="Sélectionner Module" label-for="module">
                    <v-select
                      v-model="formData.module"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="modulesOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="module" 

                      @change="getElementsByModule(formData.module)"
                        @select="getElementsByModule('select')"
                        @search="getElementsByModule('search')"
                        @input="getElementsByModule(formData.module)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6" md="3" v-if="formData.module">
                  <b-form-group>
                  <label label-for="reference">Sélectionner {{ reference_selected }}</label>
                    <v-select
                      v-model="formData.reference"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="referencesOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="reference"

                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6" md="3">
                  <!-- form_type -->
                  <b-form-group
                    label="Type de Formulaire"
                    label-for="form_type"
                  >
                    <v-select
                      v-model="formData.form_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="form_type"
                    />
                  </b-form-group>
                </b-col> 
                
              </b-row>
              <b-row class="">
                <!-- Name -->
                <b-col cols="12" md="12">
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      label="Nom / Designation du Formulaire"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="formData.name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Informations sur les agents DFJ, Beneficiaires DFJ/AVEC,..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer le nom du formulaire s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- description -->
                <b-col cols="12" md="12">
                  <validation-provider
                    #default="validationContext"
                    name="description"
                    rules="required"
                  >
                    <b-form-group
                      label="Description du formulaire"
                      label-for="description"
                    >
                      <b-form-input
                        id="description"
                        v-model="formData.description"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Le formulaire a pour but de..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer la description du formulaire s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12" md="12">
                  <b-form-group>
                    <b-form-checkbox
                      :checked="showFormCreatorGui"
                      v-model="showFormCreatorGui"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      Afficher Créateur Visuel (Après Enregistrement)
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- PERMISSION TABLE -->
        <!-- <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18" />
              <span class="align-middle ml-50">Administrateur</span>
            </b-card-title>
          </b-card-header>
          <b-card no-body class="invoice-preview-card">
            <b-row class="p-10 p10" style="padding: 10px"> 
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="director"
                  rules="required"
                >
                  <b-form-group
                    label="Le gestionnaire regionale principal"
                    label-for="director"
                  >
                    <b-form-input
                      id="director"
                      v-model="formData.director"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Ex: SKU-002"
                      class=""
                    />
                    <b-form-invalid-feedback>
                      Compléter le code de l'Administrateur du Formulaire
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
        </b-card> -->

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>

            <!-- <router-link :to="{ name: 'user', params: { userId: 123 } }"
              >User</router-link
            > -->

            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="formData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";
// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BMedia,
  BAvatar,
  BTable,
  BCardHeader,
  BCardTitle,

  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "form";
const userData = JSON.parse(localStorage.getItem("userData"));

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,

    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      reference_selected: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      modulesOptions: [],
      referencesOptions: [],
      showFormCreatorGui: false,
    };
  },
  props: {
    formData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    // console
    if (router.currentRoute.name == "form-list-add-new") {
      this.operationTitle = "Nouveau ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == "form-list-view") {
      this.operationTitle = "Détails du ";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == "form-list-edit") {
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    reinitForm() {
      //
      this.$props.formData.name = "";
      this.$props.formData.sku_form = "";
      this.$props.formData.sku_department = "";
      this.$props.formData.sku_module = "";
      this.$props.formData.sku_reference = "";
      this.$props.formData.form_type = "";
      // remove all occurences
      // initialise 1 element
      // this.$props.formData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.formData.name == "") {
        this.errors.push("Compléter le nom du form svp.");
      }
      if (this.errors.length > 0) {
        // console.log(this.errors);
        return false;
      } else {
        return true;
      }
    },
    getDepartmentModules(department_selected) {
      // offline get
      const userData = JSON.parse(localStorage.getItem("userData"));
      var departmentData = userData.departmentData;

      // reinit modules
      this.modulesOptions = [];

      for (let index = 0; index < departmentData.length; index++) {
        const department = departmentData[index];
        if (department.name == department_selected) {
          for (
            let module_index = 0;
            module_index <
            JSON.parse(JSON.stringify(departmentData[index].modules)).length;
            module_index++
          ) {
            var modules = JSON.parse(
              JSON.stringify(departmentData[index].modules)
            );
            // add to array
            this.modulesOptions.push({
              label: modules[module_index].module,
              value: modules[module_index].ressource,
            });
          }
        }
      }
    },
    async getElementsByModule(element_selected) {
      // dynamic get online
      this.reference_selected = element_selected;

      // 
      this.referencesOptions = [],

      await myApi
      .get(element_selected)
      .then((response) => {
        // default action
        var data = response.data.data;
        var $sku_element = `sku_`+element_selected;

        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.referencesOptions.push({
            label: data[i].name,
            value: data[i].$sku_element,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
    },
    async addUpdateItemAction() {
      // console.log(this.permissionsData);

      //////////////////////////////////////////////////////////////

      if (this.formReadyForSubmission() == false) {
        // console.log(this.refFormObserver.fields);
        return;
      }

      const defaultActionText = this.actionName;

      let formData = new FormData();
      let method = "";
      let params = "";

      if (router.currentRoute.name == "form-list-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "form-list-view") {
        method = "get";
      }
      if (router.currentRoute.name == "form-list-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.form - listData.id;
      }

      // finalise form data
      formData = this.$props.formData;

      await myApi({
        url: "form" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            if (this.showFormCreatorGui === false) {
              this.$swal({
                title: "Fin de l'opération",
                text: response.data.message,
                icon: "success",
                timer: 5000,
                showCancelButton: true,
                confirmButtonText: "Afficher Formulaire Vide",
                cancelButtonText: "Afficher Liste ?",
                customClass: {
                  confirmButton: "btn btn-success",
                  cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  // stay here
                  // reinit form
                  this.reinitForm();
                } else if (result.dismiss === "cancel") {
                  // redirection to list
                  router.push({ name: "form-list" });
                }
              });
            } else {
              if(this.formData.form_type==`dynamic`){
              
                // redirect to dynamic form builder gui
                router.push({ name: "form-dynamic-designer",  params: { id: response.data.id, name: response.data.name, description: response.data.description,  }  });
              }else{
                // redirect to static form builder gui 
                router.push({ name: "form-designer" ,  params: { token: localStorage.getItem("token"), username: userData.username, fullname: userData.fullName, role: userData.role, id: response.data.id, name: response.data.name, return_url: window.location.href, api_endpoint: $themeConfig.app.api_endpoint } });
              }
              
            }
          } else {
            this.actionName = defaultActionText;
            console.log(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.errors,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          console.log(error.response.data.errors);

          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.errors,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "form-list-view" });
    },
    goToNextItem() {
      router.push({ name: "form-list" });
    },
    goToList() {
      router.push({ name: "form-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-form";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.$props.formData = this.$props.moduleData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmoduleData);
    //
    let typeOptions = [
      { label: "Formulaire Dynamique", value: "dynamic" },
      { label: "Formulaire Statique", value: "static" },
    ];

    const formOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    var departmentOptions = [];

    const userData = JSON.parse(localStorage.getItem("userData"));
    const permissions = JSON.parse(JSON.stringify(userData.departmentData));

    for (let index = 0; index < permissions.length; index++) {
      const department = permissions[index];
      departmentOptions.push({
        label: department.name,
        value: department.name,
      });
    }

    return {
      // itemsList,
      refFormObserver,
      getValidationState,
      resetForm,
      // permissionsData,
      departmentOptions,
      typeOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
